// import PageHeader from '@/components/PageHeader'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import root from '@/minxin/root'

const Index = {
  name: 'EnterprisInfo',
  mixins: [root],
  data() {
    return {
      root_name: 'qyxinxi',
      root_msg: '企业信息',
      showSetInfo: false,
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-01',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-03',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }],
      loading: false,
      ruleForm: {
        CompanyName: '', // 公司名称
        SaleContact: '',
        SAPSTCD5: '',
        SaleContactTel: '',
        CompanyAddress: '',
        WriteTime: '',
        CompanyTypeString: '',
        ValidTo: '',
        date: [],
        BeginTime: '',
        IsLongRange: false
      },
      rules: {},
      checkedCities: [],
      allTypes: [
        '买家', '卖家（供应商）'
      ],
      showAdd: false,
      cardInfo: {
        IDX: '',
        PhotoUrl: '', // 本地路径,
        CertificateCode: '', // 证件编号
        ValidTo: '', // 证件有效期
        IsLongRange: false,
        TypeID: '' // 证件类型
      },
      form: {
        CompanyName: '', // 公司名称
        SaleContact: '',
        SAPSTCD5: '',
        BeginTime: '',
        SaleContactTel: '',
        CompanyAddress: '',
        WriteTime: '',
        CompanyTypeString: '',
        ValidTo: '',
        CompanyType: '',
        IsLongRange: false
      },
      rules1: {
        TypeID: [
          { required: true, message: '请选择证件名称', trigger: 'blur' }
        ],
        PhotoUrl: [
          { required: true, message: '请上传证照', trigger: 'blur' }
        ],
        ValidTo: [
          { required: true, message: '请选择有效期', trigger: 'blur' }
        ]
      },
      types: [],
      showViewer: false,
      item: {},
      fileData: {
        FileName: '',
        Base64String: ''
      }
    }
  },
  components: {
    ElImageViewer
  },
  mounted() {
    this.GetCurrentCompanyInfo()
    this.GetCertificateType()
  },
  methods: {
    // 获取经营范围
    GetCertificateType() {
      this.$api.GetCertificateType({
        CertificateType: 1
      }).then(res => {
        console.log(res)
        this.types = res.Message
      })
    },
    // 企业修改
    toEdit() {
      console.log(this.form)
      // this.$router.push({
      //   name: 'Merchants1',
      //   params: {
      //     IDX: this.form.IDX,
      //     active: 1
      //   }
      // })
    },
    // 获取企业信息
    GetCurrentCompanyInfo() {
      this.$api.GetCurrentCompanyInfo().then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.form = res.Message
        this.form.IsLongRange ? this.form.IsLongRange = true : this.form.IsLongRange = false
        // if (this.ruleForm.BeginTime && this.ruleForm.ValidTo) {
        //   this.ruleForm.date.push(this.ruleForm.BeginTime)
        //   this.ruleForm.date.push(this.ruleForm.ValidTo)
        // }
        if (Array.isArray(res.Message.CompanyCertificateList)) {
          this.tableData = res.Message.CompanyCertificateList
        }

        // for (const key in this.form) {
        //   if (res.Message[key]) {
        //     this.form[key] = res.Message[key]
        //   }
        // }
      })
    },
    // 证照上传
    AddCompanyCertificate() {

    },
    handleFileChange(e) {
      const file = e.target.files[0]
      const size = Math.floor(file.size / 1024 / 1024)
      if (size > 5) {
        return this.$message({
          message: '图片大小不能超过5M',
          type: 'warning'
        })
      }
      var reader = new FileReader()
      reader.readAsDataURL(file)
      console.log(file)
      this.fileData.FileName = file.name
      reader.onload = (e) => {
        console.log(e.target)
        // this.fileData.FileName = _file.name
        this.fileData.Base64String = e.target.result
        this.$api.UploadRegistCertificate(this.fileData).then(res => {
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.$message.success('选择证照成功')
          this.cardInfo.PhotoUrl = res.Message // 营业执照地址
        }).catch(err => {
          return this.$message.error('错误：' + err)
        })
      }
    },
    edit() {
      this.showSetInfo = true
      this.ruleForm.date = []
      console.log('form', this.form)
      for (const key in this.form) {
        this.ruleForm[key] = this.form[key]
      }
      this.ruleForm.date.push(this.form.BeginTime)
      this.ruleForm.date.push(this.form.ValidTo)
      console.log(this.ruleForm)
    },
    close(e) {
      this.showSetInfo = false
      this.$refs.ruleForm.resetFields()
    },
    submit(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        console.log(this.ruleForm)
        if (this.ruleForm.date.length > 0) {
          // console
          this.ruleForm.BeginTime = this.ruleForm.date[0]
          this.ruleForm.ValidTo = this.ruleForm.date[1]
        }
        this.ruleForm.IsLongRange ? this.ruleForm.IsLongRange = 1 : this.ruleForm.IsLongRange = 0
        this.loading = true
        this.$api.UpdateCurrentCompanyInfo(this.ruleForm).then(res => {
          console.log(res)
          this.loading = false
          if (res.RetCode !== '0') return this.$message.error('错误:' + res.RetMsg)
          this.GetCurrentCompanyInfo()
          this.$refs.ruleForm.resetFields()
          this.showSetInfo = false
          this.$message.success('企业信息修改成功')
        }).catch(err => {
          this.showSetInfo = false
          return this.$message.error('错误:' + err)
        })
      })
    },
    addCard() {
      this.showAdd = true
      this.cardInfo = {
        IDX: '',
        PhotoUrl: '', // 本地路径,
        CertificateCode: '', // 证件编号
        ValidTo: '', // 证件有效期
        IsLongRange: false,
        TypeID: '' // 证件类型
      }
    },
    close1() {
      this.showAdd = false
      this.$refs['cardInfo'].resetFields()
      document.getElementById('file_table').outerHTML = document.getElementById('file_table').outerHTML
    },
    // 编辑证照
    editCard(row) {
      this.showAdd = true
      for (const key in this.cardInfo) {
        this.cardInfo[key] = row[key]
      }
      console.log(this.cardInfo)
    },
    // 编辑证照EditCompanyCertificate
    submit1(formName) {
      this.$refs[formName].validate((valid) => {
        if (!valid) return false
        console.log(this.cardInfo)
        this.cardInfo.IsLongRange ? this.cardInfo.IsLongRange = 1 : this.cardInfo.IsLongRange = 0
        if (!this.cardInfo.IDX) {
          console.log('this.form', this.form)
          if (this.form.YYZZIDX && this.cardInfo.TypeID === 0) {
            return this.$message.error('营业执照已存在')
          }
          this.$api.AddCompanyCertificate(this.cardInfo).then(res => {
            this.showAdd = false
            if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
            this.GetCurrentCompanyInfo()
            this.$refs['cardInfo'].resetFields()
            document.getElementById('file_table').outerHTML = document.getElementById('file_table').outerHTML
            return this.$message.success('上传成功')
          })
          return
        }
        // 编辑证照
        this.$api.EditCompanyCertificate(this.cardInfo).then(res => {
          this.showAdd = false
          if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
          this.GetCurrentCompanyInfo()
          this.$refs['cardInfo'].resetFields()
          document.getElementById('file_table').outerHTML = document.getElementById('file_table').outerHTML
          return this.$message.success('编辑成功')
        })
      })
    },
    // 删除证照
    DeleteCompanyCertificate(idx) {
      this.$api.DeleteCompanyCertificate({
        idx
      }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.GetCurrentCompanyInfo()
        return this.$message.success('删除成功')
      })
    },
    // 预览证照
    viewPhoto(row) {
      this.showViewer = true
      this.item = row
    },
    downLoad(row) {
      console.log(row.PhotoUrl)
      this.$api.GetFirstCampFiles({
        FileURL: this.b2bstaticUrl + '/' + row.PhotoUrl
      }).then(res => {
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.$minCommon.downloadFile(res.Message, '.' + row.PhotoUrl.split('.')[1])
      })
    }
  }
}

export default Index
