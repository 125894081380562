<template>
  <div class="EnterprisInfo p-lr-20 p-tb-20">

    <div class="userinfo_header flex column">
      <div class="header p-lr-20 flex j-between a-center">
        <div class="weight">企业信息</div>
        <div>
          <!-- <span class="p-lr-20 theme-color cursor" @click="toEdit">企业更改</span> -->
          <span class="theme-color cursor" @click="edit">修改</span>
        </div>
      </div>
      <div class="main_view p-lr-20 flex column">
        <p class="f12 p-lr-20"><span class="p-right-5" style="color: red">|</span>企业基本信息: </p>
        <div class="flex j-start p-lr-30 a-center">
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">单位企业名称: </div>
            <div class="item value f12">{{ form.CompanyName }}</div>
          </div>
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">联系人: </div>
            <div class="item value f12">{{ form.SaleContact }}</div>
          </div>
        </div>
        <div class="flex j-start p-lr-30 a-center">
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">社会统一信用编码: </div>
            <div class="item value f12">{{ form.SAPSTCD5 }}</div>
          </div>
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">联系电话: </div>
            <div class="item value f12">{{ form.SaleContactTel }}</div>
          </div>
        </div>
        <div class="flex j-start p-lr-30 a-center">
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">证件有效期: </div>
            <div class="item value f12">{{ form.IsLongRange ? '长期有效' : form.ValidTo }}</div>
          </div>
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">单位地址: </div>
            <div class="item value f12">{{ form.CompanyAddress }}</div>
          </div>
        </div>
        <div class="flex j-start p-lr-30 a-center">
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">创建时间: </div>
            <div class="item value f12">{{ form.WriteTime }}</div>
          </div>
          <div class="item_main flex ">
            <div class="item title f12 p-right-10">单位类型: </div>
            <div class="item value f12">{{ form.CompanyTypeString }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="add_card m-tb-20 flex j-end">
      <el-button type="primary" size="mini" round @click="addCard">添加新证件</el-button>
    </div>
    <!-- 表格 -->
    <div class="m-tb-10">
      <el-table
        ref="multipleTable"
        :header-cell-style="{background:'#EFEFEF'}"
        :data="tableData"
        tooltip-effect="dark"
        show-header
        style="width: 100%"
      >
        <el-table-column prop="TypeString" label="证件类型" align="center" />
        <el-table-column prop="CertificateCode" label="证件编号" align="center" />
        <el-table-column prop="NickName" label="上传人" align="center" />
        <el-table-column prop="WriteTime" label="上传时间" align="center" />
        <el-table-column prop="ValidToString" label="证件有效期" align="center" />
        <el-table-column prop="StatusName  " label="状态" align="center" />
        <el-table-column
          align="center"
          prop="set"
          label="操作"
        >
          <template slot-scope="scope">
            <el-popover
              placement="left"
              trigger="hover"
              :width="50"
              popper-class="test_pop_view"
            >
              <div>
                <div
                  class="cursor"
                  @click.stop="viewPhoto(scope.row)"
                >
                  <i class="el-icon-view p-right-5" />
                  <el-link
                    type="primary"
                    :underline="false"
                  >预览</el-link>
                </div>
                <div
                  class="cursor"
                  @click="editCard(scope.row)"
                >
                  <i class="el-icon-edit p-right-5" />
                  <span><el-link
                    type="primary"
                    :underline="false"
                  >编辑</el-link></span>
                </div>
                <div
                  class="cursor"
                  @click="DeleteCompanyCertificate(scope.row.IDX)"
                >
                  <i class="el-icon-delete p-right-5" />
                  <span><el-link
                    type="primary"
                    :underline="false"
                  >删除</el-link></span>
                </div>
                <div class="cursor" @click="downLoad(scope.row)">
                  <i class="el-icon-download p-right-5" />
                  <span><el-link
                    type="primary"
                    :underline="false"
                  >下载</el-link></span>
                </div>
              </div>
              <i slot="reference" class="el-icon-more" />
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 企业信息修改 -->
    <el-dialog :visible="showSetInfo" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="dialog_view" :show-close="false">
      <div class="p-lr-20">
        <div class="PageHeader1 min-border-bottom flex j-between">
          <p class="p-left-20 weight">企业信息修改</p>
          <p class="p-right-10 weight f16 cursor" style="color:#000" @click="close('ruleForm')">x</p>
        </div>
        <div class="m-top-20" />
        <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="100px" class="demo-ruleForm">
          <el-form-item label="单位名称：" prop="Province">
            {{ ruleForm.CompanyName }}
          <!-- <el-input v-model="ruleForm.Province" size="mini" /> -->
          </el-form-item>
          <el-form-item label="社会统一信用代码：" prop="City">
            {{ ruleForm.SAPSTCD5 }}
          <!-- <el-input v-model="ruleForm.City" size="mini" /> -->
          </el-form-item>
          <el-form-item label="证件有效期：" prop="date">
            <el-date-picker
              v-if="!ruleForm.IsLongRange"
              v-model="ruleForm.date"
              type="daterange"
              :unlink-panels="true"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              size="mini"
              align="center"
              style="width:202px"
              value-format="yyyy-MM-dd"
            />
            <el-checkbox v-model="ruleForm.IsLongRange" class="p-left-10">长期有效</el-checkbox>
          <!-- <el-input v-model="ruleForm.Area" size="mini" /> -->
          </el-form-item>
          <el-form-item label="联系人：" prop="SaleContact">
            <el-input v-model="ruleForm.SaleContact" size="mini" />
          </el-form-item>
          <el-form-item label="联系电话：" prop="SaleContactTel">
            <el-input v-model="ruleForm.SaleContactTel" size="mini" />
          </el-form-item>
          <el-form-item label="单位地址：" prop="CompanyAddress">
            <el-input v-model="ruleForm.CompanyAddress" size="mini" />
          </el-form-item>
          <!-- <el-form-item label="单位类型：" prop="CompanyType">
            <el-radio-group v-model="ruleForm.CompanyType">
              <el-radio :label="1">买家</el-radio>
              <el-radio :label="4">卖家（供应商）</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item>
            <el-button type="info" size="mini" @click="close('ruleForm')">取消</el-button>
            <el-button :loading="loading" type="primary" size="mini" @click="submit('ruleForm')">确定</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-dialog>
    <!-- 上传证件 -->
    <el-dialog :visible="showAdd" :close-on-click-modal="false" :close-on-press-escape="false" custom-class="dialog_view" :show-close="false">
      <div class="p-lr-20">
        <div class="PageHeader1 min-border-bottom flex j-between">
          <p class="p-left-20 weight">企业信息修改</p>
          <p class="p-right-10 weight f16 cursor" style="color:#000" @click="close1('cardInfo')">x</p>
        </div>
        <div class="m-top-20" />
        <el-form ref="cardInfo" :model="cardInfo" :rules="rules1" label-width="100px" class="demo-ruleForm">
          <el-form-item label="选择证件：" prop="TypeID">
            <el-select v-model="cardInfo.TypeID" style="width:100%" size="small" placeholder="请选择">
              <el-option
                v-for="item1 in types"
                :key="item1.Code"
                :label="item1.Name"
                :value="item1.Code"
              />
            </el-select>
          <!-- <el-input v-model="ruleForm.Province" size="mini" /> -->
          </el-form-item>
          <el-form-item label="上传证照：" prop="PhotoUrl">
            <input id="file_table" accept=".jpg, .png, .jpeg, .gif" type="file" name="file" multiple="multiple" @change="handleFileChange">
            <el-image
              v-if="cardInfo.PhotoUrl"
              fit="contain"
              style="flex:1; max-width:300px;"
              :src="b2bstaticUrl + '/' +cardInfo.PhotoUrl"
            />
          <!-- <el-input v-model="ruleForm.City" size="mini" /> -->
          </el-form-item>
          <el-form-item label="证件编号：" prop="CertificateCode">
            <el-input v-model="cardInfo.CertificateCode" size="mini" />
          </el-form-item>
          <el-form-item label="证件有效期：" prop="ValidTo">
            <el-date-picker
              v-model="cardInfo.ValidTo"
              type="date"
              value-format="yyyy-MM-dd"
              size="mini"
              placeholder="请选择证件有效期"
            />
            <el-checkbox v-model="cardInfo.IsLongRange" class="p-left-10">长期有效</el-checkbox>
          <!-- <el-input v-model="ruleForm.Area" size="mini" /> -->
          </el-form-item>

          <el-form-item>
            <el-button type="info" size="mini" @click="close1('cardInfo')">取消</el-button>
            <el-button :loading="loading" type="primary" size="mini" @click="submit1('cardInfo')">确定</el-button>
          </el-form-item>
        </el-form>
      </div>

    </el-dialog>
    <el-image-viewer
      v-if="showViewer"
      style="z-index: 9999"
      :url-list="[b2bstaticUrl + '/' +item.PhotoUrl]"
      :on-close="() => (showViewer = false)"
    />
  </div>
</template>
<script>
import Index from '@/minxin/userinfo/EnterprisInfo'
export default Index
</script>
<style lang="scss" scoped>
.userinfo_header{
 width: 100%;
 height: 270px;
 background-color: #fff;
 border: 1px solid#dcdfe6;
  .header{
   height: 50px;
   background-color: rgb(239, 239, 239);
   line-height: 50px;
  }
  .main_view{
    width: 100%;
    height: auto;
    .item_main{
       width: 50%;
      .item {
        height: 40px;
        line-height: 50px;
      }
      .title{
        color: #ccc;
      }
      .value {
        color: #999;
      }
    }
  }
}
</style>
